import React, { Component } from "react"
import { Link } from "gatsby"
import * as JsSearch from "js-search"
import { result } from "lodash"

class ClientSearch extends Component {
  state = {
    isLoading: true,
    searchResults: [],
    search: null,
    isError: false,
    indexByTitle: false,
    indexByContent: false,
    termFrequency: true,
    removeStopWords: false,
    searchQuery: "",
    selectedStrategy: "",
    selectedSanitizer: "",
  }
  /**
   * React lifecycle method that will inject the data into the state.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.search === null) {
      // const { engine } = nextProps
      return {
        indexByTitle: true,
        indexByContent: true,
        termFrequency: true,
        selectedSanitizer: "Lower Case",
        selectedStrategy: "Prefix match",
      }
    }
    return null
  }
  async componentDidMount() {
    this.rebuildIndex()
  }

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const {
      selectedStrategy,
      selectedSanitizer,
      removeStopWords,
      termFrequency,
      indexByTitle,
      indexByContent,
    } = this.state
    const { site } = this.props
    const {searchEN} = site
    const {edges} = searchEN

    const resultArray = [];
    var transformed = edges.map(obj => {
      obj.node.searchResult.map(data => {
        //check english content only
        console.log(data)
        if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
          if(data.language_code === 'ko' && data.related_office !== 'wktokyo') {
            resultArray.push({
                id: data.id,
                language_code: data.language_code,
                title: data.title,
                type: data.type,
                slug: data.slug,
                date: data.date,
                description: data.description,
                excerpt: data.excerpt
              });
          }
        }
        else{
          if(data.language_code === 'ja' && data.related_office !== 'wkseoul') {
            resultArray.push({
                id: data.id,
                language_code: data.language_code,
                title: data.title,
                type: data.type,
                slug: data.slug,
                date: data.date,
                description: data.description,
                excerpt: data.excerpt
              });
          }
        }
        
        });
        return resultArray;
    });

    var transformedData = resultArray.map(resultData => {
    var result = resultData;
    //console.log('result_' + JSON.stringify(result));
    return result;
    })   



    const dataToSearch = new JsSearch.Search("id")

    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      )
    }
    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === "All") {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    }
    if (selectedStrategy === "Exact match") {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy()
    }
    if (selectedStrategy === "Prefix match") {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    }

    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    selectedSanitizer === "Case Sensitive"
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id"))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())

    // sets the index attribute for the data
    if (indexByTitle) {
      dataToSearch.addIndex("title")
    }
    // sets the index attribute for the data
    if (indexByContent) {
      dataToSearch.addIndex("description")
    }
    
    dataToSearch.addDocuments(transformedData) // adds the data to be searched

    // console.log(dataToSearch)

    this.setState({ search: dataToSearch, isLoading: false })
  }
  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  searchData = e => {
    const { search } = this.state
    const queryResult = search.search(e.target.value)
    this.setState({ searchQuery: e.target.value, searchResults: queryResult })
  }
  handleSubmit = e => {
    e.preventDefault()
  }
  render() {
    const { searchResults, searchQuery } = this.state
    const { site } = this.props
    const {searchEN} = site
    const {edges} = searchEN
    const queryResults = searchQuery === "" ? edges : searchResults
    return (
      <div>
        <section className="search__Page__search-form">
          <form onSubmit={this.handleSubmit}>
            <label className="u-isHiddenVisually">Search</label>
            <input
                id="Search"
                value={searchQuery}
                onChange={this.searchData}
                placeholder="Type here"
                type="text"
              />
            <input className="u-isHiddenVisually" type="submit" />
          </form>
        </section>
        <section className="search__Page__search-results">
          <ul className="search_resultList">
            {/* <h2>{queryResults.length}</h2> */}
            {queryResults.length === 0 && (<h3>Sorry, no results were found for this search query.</h3>)}
            {queryResults.filter(wType => wType.type === "work")
            .map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  {index === 0 && (<li className="search-resultsCategory work">
                    <h3>Work</h3>
                  </li>)}
                  <li>
                    <Link to={`/${item.type}/${item.slug}`}>
                        {item.date &&
                          <time>{item.date}</time>
                        }
                        <span>{item.title}</span>
                    </Link>
                  </li>
                </React.Fragment>
              )
            })
            }
            {queryResults.filter(wType => wType.type === "news")
            .map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  {index === 0 && (<li className="search-resultsCategory blog">
                    <h3>News/Blogs</h3>
                  </li>)}
                  <li>
                    <Link to={`/${item.type}/${item.slug}`}>
                        {item.date &&
                          <time>{item.date}</time>
                        }
                        <span>{item.title}</span>
                    </Link>
                  </li>
                </React.Fragment>
              )
            })
            }
            {queryResults.filter(wType => wType.type === "jobs" || wType.type === "page" && wType.slug !== "search")
            .map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  {index === 0 && (<li className="search-resultsCategory blog">
                    <h3>Others</h3>
                  </li>)}
                  <li>
                    <Link to={`/${item.slug}`}>
                        {item.date &&
                          <time>{item.date}</time>
                        }
                        <span>{item.title}</span>
                    </Link>
                  </li>
                </React.Fragment>
              )
            })
            }
          </ul>
        </section>
        </div>
    )
  }
}
export default ClientSearch