import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'

import Layout from "../layouts/App"
import SEO from "../components/seo"
import ClientSearch from "../components/ClientSearch"
import Close from '../svg/Close'

const Search = pageProps => {
  const {data} = pageProps
  return (
    <Layout>
        <SEO title="Search" />
        <article className="search__Page">
            <section className="container-fluid-custom-only content-body">
                <StaticQuery query={graphql`
                    {
                        searchEN: allWp {
                            edges {
                                node {
                                    searchResult {
                                    ...Search
                                    }
                                }
                            }
                        }
                    }
                    fragment Search on WpSearchData {
                        id
                        language_code
                        title
                        type
                        slug
                        excerpt
                        description
                        date(formatString: "DD.MM.YYYY")
                        related_office
                    }
                `} render={ props => {
                     console.log(JSON.stringify(props))
                    return(
                        <>
                            <div className="search__Page__inner">
                                <header>
                                    <h2>Search W+K Tokyo by typing...</h2>
                                </header>
                            </div>
                            <ClientSearch site={props} lang="" />
                        </>
                    )
                }} />
            </section>
        </article>
    </Layout>
  )
}

export default Search